.landing-header__auth div.landing-header__link.landing-header__dropdown:hover {
    text-decoration: none;
    cursor: pointer;
}

.landing-header__auth
    div.landing-header__link.landing-header__dropdown:hover
    .landing-header__linkContent-holder {
    display: flex;
}

.landing-header__auth .landing-header__linkContent a:last-child,
.landing-header__auth .landing-header__linkContent a:nth-child(2) {
    background-color: transparent;
    margin-left: 0;
    line-height: 21px;
    padding: 8px 0 0;
    border: 1px solid #f5f5f5;
    margin-top: 8px;
}

.landing-header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    z-index: 95;

    @media (min-width: 640px) {
        padding: 20px 0;
    }

    &__logo-wrapper {
        width: 123px;
    }

    &__menu-burger {
        @media (min-width: 1025px) {
            display: none;
        }
    }

    &__dropdown {
        position: relative;
    }

    &__linkContent-holder {
        position: absolute;
        top: 18px;
        left: -90px;
        padding-top: 16px;
        display: none;
    }

    &__linkContent {
        background: #f5f5f580;
        padding: 12px 16px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
    }

    &__logo {
        display: block;
        width: 123px;
        height: 34px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: left center;
        }

        @media (max-width: 640px) {
            width: 9rem;
            height: 2.5rem;
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        flex-basis: auto;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        justify-content: center;
        row-gap: 10px;
        column-gap: 5px;

        @media (max-width: 1024px) {
            display: none;
        }
        @media (max-width: 1300px) {
            flex-wrap: wrap;
        }
    }

    .noMargin {
        margin-left: 0 !important;
    }

    &__link {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #2330ba;
        padding: 18px 25px;
        border-radius: 10px;
        background: #e7f0ff;
        transition: opacity 0.3s;
        width: max-content;

        &:hover {
            text-decoration: underline;
        }

        &-second,
        &-profile {
            color: #122443;
            background: #fecc62;
        }

        &-profile {
            display: flex;
            column-gap: 10px;
            justify-content: center;
            align-items: center;
            padding: 13px 25px;

            img {
                width: 24px;
                height: 24px;
            }
        }
    }

    &__linkWrapper {
        position: relative;
        margin-left: 32px;

        span {
            position: absolute;
            bottom: -20px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            font-size: 15px;
            font-style: italic;
            color: #122443;
            opacity: 0.7;
            text-align: center;
        }
    }

    &__linkButton {
        font-size: 18px;
        font-weight: 500;
        text-align: left;

        color: #2330ba;
        transition: opacity 0.3s;
        width: max-content;

        padding: 0 25px;
        line-height: 56px;
        border-radius: 12px;
        background: rgba(17, 101, 250, 0.1);

        & + & {
            margin-left: 40px;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    &__auth {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media (max-width: 1024px) {
            display: none;
        }

        &-buttons {
            position: relative;

            &-login {
                position: absolute;
                top: calc(100% + 10px);
                right: 0;
                display: flex;
                flex-direction: column;
                row-gap: 5px;

                a {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px 23px;
                    width: 220px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    background: #fff;
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    color: #0f1445;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }

    &__mobMenu {
        width: 2rem;
        height: 2rem;
    }
}

.landing-main {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 4rem calc((100% - 1140px) / 2) 0;

    @media (max-width: 1220px) {
        padding: 4rem 2.5rem 0;
    }

    @media (max-width: 640px) {
        padding: 30px 1.5rem 0;
    }

    &__title {
        font-family: "Gilroy", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 72px;
        line-height: 110%;
        text-align: center;
        color: #2330ba;
        margin-bottom: 2.5rem;

        @media (max-width: 1024px) {
            font-weight: 600;
            font-size: 56px;
            line-height: 110%;
        }

        @media (max-width: 640px) {
            font-weight: 600;
            font-size: 26px;
            line-height: 110%;
            margin-bottom: 23px;
        }
    }

    &__row {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (max-width: 640px) {
            flex-direction: column-reverse;
        }
    }

    &__text {
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        letter-spacing: 0.03em;
        text-align: center;
        color: #2330ba;
        width: 1210px;
        margin-bottom: 37px;

        .mob-br {
            display: none !important;
        }

        span {
            font-weight: 600;
            letter-spacing: 0.03em;
            text-align: center;
        }

        @media (max-width: 1100px) {
            width: 100%;

            br {
                display: none;
            }
        }
        @media (max-width: 640px) {
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.03em;
            text-align: center;

            .mob-br {
                display: block !important;
            }
        }
    }

    &__btns {
        display: flex;
        align-items: center;

        @media (max-width: 640px) {
            flex-flow: column;
            padding-bottom: 3rem;
        }
    }

    &__buttons {
        &-wrapper {
            @media (max-width: 640px) {
                padding-bottom: 3rem;
            }
        }

        &-flex {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            @media (max-width: 640px) {
                flex-flow: column;
            }
        }
    }

    &__freemium {
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.03em;
        text-align: right;
        color: #122443;
        text-decoration: underline;
        padding-bottom: 5rem;
    }

    &__btn-wrapper {
        position: relative;
        //margin-left: 1.5rem;
        margin-top: 1rem;

        @media (max-width: 640px) {
            margin-left: 0;
            margin-top: 12px;
        }

        span {
            position: absolute;
            bottom: -20px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            font-size: 15px;
            font-style: italic;
            color: #122443;
            opacity: 0.7;
            text-align: center;
        }
    }

    &__btn {
        font-family: "Gilroy", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 56px;
        color: #122443;

        padding: 0 2rem;
        background: #fecc62;
        border-radius: 12px;

        transition: opacity 0.3s;

        &:hover {
            opacity: 0.7;
        }

        &_blue {
            color: #2330ba;
            background: rgba(17, 101, 250, 0.1);
        }

        &-profile {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 10px;

            img {
                width: 24px;
                height: 24px;
            }
        }
    }

    &__bnr {
        width: 100%;
        overflow: hidden;
        border-radius: 42px;

        img {
            width: 100%;
        }

        @media (max-width: 640px) {
            height: 235px;
            border-radius: 28px;

            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__video {
        width: 100%;
        max-width: 1140px;
        overflow: hidden;
        border-radius: 42px;
        position: relative;

        video {
            width: 100%;
            height: auto;
        }

        @media (max-width: 640px) {
            border-radius: 28px;
        }
    }

    &__videoControls {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(../img/ic-play.svg) no-repeat center / 120px;
        cursor: pointer;

        @media (max-width: 1024px) {
            background-size: 5rem;
        }

        &_actively {
            background: transparent;
        }
    }
}

.landing-bullets {
    width: 100%;
    max-width: 1060px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    padding: 40px calc((100% - 1140px) / 2) 45px;

    @media (max-width: 1220px) {
        padding: 94px 2.5rem 7.5rem;
    }

    @media (max-width: 640px) {
        padding: 5rem 0 20px;
    }
}

.landing-bullet {
    margin: 0 auto 2rem;
    width: 300px;
    display: flex;
    flex-flow: column;
    align-items: center;

    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #2330ba;

    &__text {
        width: 300px;
    }

    &__icon {
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f0fcff;
        margin-bottom: 20px;
        border-radius: 50%;
    }
}

.landing-about {
    margin-top: 80px;
}

.landing-about,
.landing-goal {
    width: 100%;
    background: rgba(0, 198, 255, 0.06);
    padding: 100px calc((100% - 1140px) / 2) 140px;

    font-size: 29px;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: #2330ba;

    @media (max-width: 1220px) {
        padding: 100px 2.5rem 140px;
    }

    @media (max-width: 640px) {
        font-size: 18px;
        padding: 40px 30px 40px 30px;
    }

    &__row {
        display: flex;
        margin-top: 72px;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media (max-width: 900px) {
            flex-direction: column;
        }
        @media (max-width: 640px) {
            margin-top: 40px;
        }
    }

    &__prop {
        @media (max-width: 1200px) {
            img {
                height: 50px;
            }
        }
        @media (max-width: 900px) {
            img {
                height: 30px;
                margin-right: 20px;
            }
            display: flex;
            align-items: center !important;
            flex-direction: row !important;
        }
    }

    &__leftCol {
        max-width: 382px;
        height: 779px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
            font-size: 26px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0.04em;
            text-align: left;
            margin-top: 25px;
        }

        @media (max-width: 1200px) {
            height: 600px;
            width: 250px;
            p {
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0.04em;
                text-align: left;
            }
        }
        @media (max-width: 900px) {
            margin-bottom: 60px;
            height: 300px;
            width: 400px;
            br {
                display: none;
            }
            p {
                margin: 0;
            }
        }
        @media (max-width: 640px) {
            width: 307px;
            height: 340px;
            margin-bottom: 40px;
        }
    }

    &__rightCol {
        max-width: 372px;
        height: 779px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        div {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        p {
            font-size: 26px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0.04em;
            text-align: right;
            margin-top: 25px;
        }

        @media (max-width: 1200px) {
            p {
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0.04em;
            }
            height: 600px;
            width: 250px;
        }
        @media (max-width: 900px) {
            margin-top: 60px;
            margin-bottom: 60px;
            height: 300px;
            width: 400px;
            align-items: flex-start;
            br {
                display: none;
            }
            p {
                margin: 0;
                text-align: left;
            }
        }
        @media (max-width: 640px) {
            width: 307px;
            margin-top: 40px;
            height: 380px;
            margin-bottom: 0;
        }
    }

    &__middleCol {
        img {
            width: 352px;
        }

        @media (max-width: 1200px) {
            img {
                width: 300px;
            }
        }
        @media (max-width: 900px) {
        }
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 72px;
        line-height: 110%;
        margin-bottom: 1.5rem;

        @media (max-width: 1024px) {
            font-size: 56px;
        }

        @media (max-width: 640px) {
            font-size: 48px;
        }
    }

    &__title-center {
        font-style: normal;
        font-weight: 600;
        font-size: 72px;
        line-height: 110%;
        margin-bottom: 0;
        text-align: center;
        @media (max-width: 1024px) {
            font-size: 56px;
        }

        @media (max-width: 640px) {
            font-size: 48px;
        }
    }

    &__list-item {
        margin-bottom: 20px;
        padding-left: 5px;
        margin-left: -5px;

        @media (max-width: 640px) {
            padding-left: 0;
            margin-left: 0;
        }
    }
}

.landing-goal__title {
    margin-bottom: 55px;

    @media (max-width: 640px) {
        font-size: 38px;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: 0;
        text-align: center;
        margin-bottom: 37px;
    }
}

.landing-goal {
    @media (max-width: 640px) {
        background: transparent !important;
        padding-top: 0;

        &__title {
            display: none;
        }
    }

    &__list-item {
        margin-bottom: 0;
    }

    &__list {
        display: flex;
        flex-direction: column;

        label {
            font-size: 30px;
            font-style: italic;
            font-weight: 500;
            line-height: 40px;
            letter-spacing: 0;
            text-align: right;
            color: #0f1445;
            margin-left: auto;
            margin-top: 32px;
            @media (max-width: 640px) {
                font-size: 18px;
                font-style: italic;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0;
                text-align: right;
                margin-top: 0;
            }
        }

        p {
            margin-bottom: 35px;
            font-size: 28px;
            font-weight: 500;
            line-height: 42px;
            letter-spacing: 0.03em;
            text-align: left;
            @media (max-width: 640px) {
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                letter-spacing: 0.03em;
                text-align: left;
            }
        }

        span {
            font-size: 28px;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0.03em;
            text-align: left;
            @media (max-width: 640px) {
                font-size: 18px;
                font-weight: 600;
                line-height: 27px;
                letter-spacing: 0.03em;
                text-align: left;
            }
        }
    }
}

.landing-goal {
    background: #fffef0;
}

.forMob {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 280px;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: #122443;
    background: #fecc62;
    border-radius: 12px;
    transition-duration: 300ms;
    margin-left: auto;
    margin-right: auto;
    margin-top: 36px;
    @media (min-width: 900px) {
        display: none;
    }
}

.quiz {
    @media (max-width: 640px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.landing-services .alertBlock {
    background: #fffdf0;
    padding: 48px 58px;
    border-radius: 50px;

    @media (max-width: 640px) {
        padding: 30px 10px 30px 30px;
        border-radius: 38px;
    }
}

.alertBlock {
    margin-bottom: 128px;

    &__row {
        display: flex;

        img {
            margin-right: 60px;
            height: 62px;
            margin-top: 16px;
            width: 64px;
        }

        p {
            color: #2330ba;
            font-size: 26px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 0.03em;
            text-align: left;
            margin-top: 27px;
        }

        @media (max-width: 640px) {
            img {
                width: 28px;
                height: 28px;
                margin-bottom: 19px;
                margin-top: 0;
            }
            p {
                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 0.03em;
                text-align: left;
                margin-top: 0;
            }
        }

        @media (max-width: 900px) {
            flex-direction: column;
        }
    }

    .p-2 {
        color: #2330ba;
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0.03em;
        text-align: left;
        margin-top: 27px;
    }

    @media (max-width: 640px) {
        .p-2 {
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0.03em;
            text-align: left;
        }
    }
}

#menuOpener {
    position: absolute;
    top: 34px;
    right: 32px;
    cursor: pointer;
}

#menuOpener:hover {
    opacity: 0.7;
}

#menu {
    position: fixed;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #122443;
    z-index: 10;
    transition-duration: 500ms;

    @media (min-width: 640px) {
        display: none !important;
    }
}

#menuOpener {
    @media (min-width: 640px) {
        display: none !important;
    }
}

.menu {
    top: calc(-100% - 663px);
}

.menu.opened {
    top: 0;
}

.menu {
    &__row {
        display: flex;
        justify-content: space-between;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 20px;
        margin-bottom: 50px;

        button {
            width: 39px;
            height: 39px;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-radius: 50%;
            transition-duration: 300ms;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: calc(100% - 119px);
        padding-bottom: 36px;
    }

    &__loginBtn {
        margin-top: 40px;
        width: 168px;
    }

    &__freeBtn {
        position: relative;
        margin-top: 16px;
        width: 188px;

        span {
            position: absolute;
            bottom: -20px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            font-style: italic;
            opacity: 0.8;
        }
    }

    &__loginBtn,
    &__freeBtn {
        border: 2px solid #ffffff;

        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: transparent;
        border-radius: 12px;
        margin-left: auto;
        margin-right: 30px;
        transition-duration: 300ms;

        &:hover {
            background-color: white;
            color: #122443;
        }
    }

    &__contacts {
        margin-top: auto;
        padding-left: 20px;

        &-row {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            a {
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                letter-spacing: 0.03em;
                text-align: left;
                color: white;
                text-decoration: none;
                cursor: pointer;
            }

            a:hover {
                text-decoration: underline;
            }
        }

        &-imgHolder {
            width: 39px;
            height: 39px;
            align-items: center;
            justify-content: center;
            display: flex;
            border-radius: 50%;
            background-color: #ffffff0f;
            margin-right: 20px;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 30px;

        a {
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0;
            color: white;
            text-align: left;
            text-decoration: none;
            cursor: pointer;
            margin-bottom: 7px;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

.landing-services {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10rem calc((100% - 1140px) / 2);
    padding-bottom: 6rem;

    @media (max-width: 1220px) {
        padding: 7rem 2.5rem;
    }

    @media (max-width: 1024px) {
        flex-flow: column;
        justify-content: initial;
    }

    @media (max-width: 640px) {
        padding: 2rem 1rem;
        padding-bottom: 10px;
    }

    &__title {
        font-size: 72px;
        font-weight: 600;
        line-height: 79px;
        letter-spacing: 0;
        text-align: left;
        color: #2330ba;
        margin-bottom: 77px;

        br {
            display: none;
        }

        @media (max-width: 640px) {
            font-size: 38px;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0;
            text-align: center;
            margin-bottom: 30px;

            br {
                display: block;
            }
        }
    }

    &__buttons {
        width: 100%;
        display: flex;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 100px;
        @media (max-width: 1100px) {
            margin-bottom: 50px;
        }
        @media (max-width: 900px) {
            margin-bottom: 40px;
        }
    }

    &__methodologistRow {
        display: flex;
        margin-bottom: 60px;

        @media (max-width: 1100px) {
            flex-direction: column-reverse;
        }

        @media (max-width: 640px) {
            margin-bottom: 20px;
        }
    }

    &__blockText {
        display: flex;
        flex-direction: column;
        @media (max-width: 640px) {
            margin-bottom: 20px;
        }

        .rowDot {
            display: flex;
            margin-bottom: 25px;

            div {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                margin-right: 20px;
                margin-top: 12px;
            }

            .dot-blue {
                background: #47a0ff;
            }

            .dot-yellow {
                background: #fecc62;
            }

            .dot-red {
                background: #ff8d4d;
            }

            p {
                font-size: 26px;
                font-weight: 500;
                line-height: 31px;
                letter-spacing: 0.04em;
                text-align: left;
                color: #2330ba;
            }

            .p-390 {
                max-width: 390px;
            }

            .p-475 {
                max-width: 475px;
            }

            @media (max-width: 900px) {
                align-items: center;
                div {
                    margin-top: 0;
                }
                p {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0.04em;
                    text-align: left;
                }
                .p-390 {
                    max-width: 294px;
                }
                .p-475 {
                    max-width: 294px;
                }
            }
        }

        button {
            margin-top: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 56px;
            width: 280px;
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0;
            text-align: left;
            color: #122443;
            background: #fecc62;
            border-radius: 12px;
            transition-duration: 300ms;
            @media (max-width: 900px) {
                display: none;
            }
        }

        button:hover {
            background: rgba(254, 204, 98, 0.71);
        }
    }

    &__blocksContent {
        margin-bottom: 207px;

        @media (max-width: 640px) {
            margin-bottom: 80px;
        }
    }

    &__blockContent {
        display: flex;
        justify-content: space-between;

        img {
            width: 50%;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 50px;
        }

        @media (max-width: 900px) {
            flex-direction: column;
            align-items: center;

            img {
                width: 312px;
            }
        }
    }

    &__button:nth-child(2),
    &__button-active:nth-child(2) {
        border-left: 1px solid #122443;
        border-right: 1px solid #122443;
    }

    &__button:hover {
        background: rgba(17, 101, 250, 0.22);
    }

    &__button-active:hover {
        background: rgba(254, 204, 98, 0.71);
    }

    &__button {
        width: calc(100% / 3);
        height: 107px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.08em;
        text-align: center;
        text-transform: uppercase;
        background: #1165fa1a;
        color: #2330ba;
        transition-duration: 300ms;

        &-active {
            width: calc(100% / 3);
            height: 107px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fecc62;
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.08em;
            text-align: center;
            text-transform: uppercase;
            transition-duration: 300ms;
        }

        @media (max-width: 1100px) {
            height: 67px;

            &-active {
                height: 67px;
            }
        }
        @media (max-width: 640px) {
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: 0.08em;
            text-align: center;
            height: 40px;

            &-active {
                height: 40px;
                font-size: 10px;
                font-weight: 500;
                line-height: 12px;
                letter-spacing: 0.08em;
                text-align: center;
            }
        }
    }

    &__method-text {
        font-size: 26px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0.04em;
        text-align: left;
        color: #2330ba;
        margin-bottom: 35px;
        @media (max-width: 640px) {
            font-family: "Gilroy", sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0.04em;
            text-align: left;
            width: 313px;
        }
    }

    &__quiz-text {
        margin-bottom: 37px;
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0.04em;
        text-align: left;
        color: #122443;

        @media (max-width: 640px) {
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0.04em;
            text-align: left;
            width: 313px;
        }
    }

    &__quizRow {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 151px;
        max-width: 945px;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 640px) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 105px;
        }

        .col {
            display: flex;
            align-items: center;
            flex-direction: column;

            @media (max-width: 640px) {
                margin-bottom: 50px;
            }

            p {
                font-size: 26px;
                font-weight: 500;
                line-height: 36px;
                letter-spacing: 0.04em;
                text-align: center;
                color: #2330ba;
                max-width: 228px;
                @media (max-width: 640px) {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 25px;
                    letter-spacing: 0.04em;
                    text-align: center;
                }
            }

            div {
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .numberHolder-big {
                width: 111px;
                height: 111px;
                font-size: 32px;
                font-weight: 900;
                line-height: 36px;
                letter-spacing: 0.08em;
                text-align: center;
                max-width: 228px;
                background-color: #2330ba;
                color: white;
                margin-bottom: 21px;
            }

            .numberHolder-small {
                background-color: #f0fcff;
                width: 80px;
                height: 80px;
                font-size: 32px;
                font-weight: 600;
                line-height: 36px;
                letter-spacing: 0.08em;
                text-align: left;
                color: #2330ba;
                margin-bottom: 21px;
            }
        }
    }

    &__methodologistRow {
        justify-content: space-between;
    }

    &__methodologist {
        width: calc(50% - 20px);
        padding: 40px;
        border-radius: 50px;
        background-color: #f0fcff;
        position: relative;

        @media (max-width: 1100px) {
            width: 100%;
            margin-bottom: 40px;
            &:last-child {
                background-color: #fffdf0;
            }
        }

        @media (max-width: 740px) {
            img {
                width: 100px !important;
                height: 100px !important;
                right: 18px !important;
                top: 36px !important;
            }
        }
        @media (max-width: 640px) {
            padding: 40px 10px;
            margin-bottom: 20px;
            h3 {
                margin-left: 10px;
            }

            &:first-child {
                background-color: #f0fcff;
            }
        }

        span {
            font-size: 26px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0.04em;
            text-align: right;

            @media (max-width: 900px) {
                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 0.04em;
                text-align: left;
            }
        }

        h3 {
            font-size: 38px;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #122443;
            margin-left: 15px;
            @media (max-width: 900px) {
                font-size: 26px;
                font-weight: 600;
                line-height: 29px;
                letter-spacing: 0.04em;
                text-align: left;
                margin-left: 20px;
            }
        }

        ul {
            list-style-position: outside;
        }

        li {
            font-size: 26px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0.04em;
            text-align: left;
            margin-bottom: 10px;
            padding-left: 10px;
            margin-left: 35px;

            @media (max-width: 900px) {
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0.04em;
                text-align: left;
            }
            @media (max-width: 640px) {
                margin-bottom: 20px;
            }
        }

        img {
            width: 126px;
            position: absolute;
            top: 43px;
            right: 43px;
        }

        .mob {
            display: none;

            @media (max-width: 900px) {
                display: block;
                width: 100px;
                top: 30px;
                right: 30px;
            }
        }
    }

    &__contentBlock {
        width: 100%;
        display: flex;
        padding: 40px;
        background-color: #fffdf0;
        border-radius: 50px;
        margin-bottom: 40px;

        img {
            width: 50px;
            height: 50px;
            margin-right: 50px;
        }

        h4 {
            font-size: 38px;
            font-weight: 600;
            line-height: 53px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #2330ba;
            margin-bottom: 50px;
        }

        li {
            font-size: 26px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #2330ba;
            padding-left: 5px;
            margin-left: -5px;
        }

        @media (max-width: 640px) {
            padding: 30px 20px;
            img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }

            h4 {
                font-size: 26px;
                font-weight: 600;
                line-height: 28px;
                letter-spacing: 0.04em;
                text-align: left;
                margin-bottom: 20px;
            }
            ul {
                margin-left: -19px;
            }
            li {
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0.04em;
                text-align: left;
                margin-bottom: 10px;
            }
        }
    }

    &__contentBlock-blue {
        background-color: #f0fcff;

        @media (max-width: 640px) {
            background-color: #fffdf0;
            padding-top: 20px;

            img {
                margin-top: 15px;
            }
        }
    }

    &__docsTitle {
        margin-bottom: 27px;
    }

    &__docsText {
        font-size: 25px;
        font-weight: 500;
        line-height: 39px;
        letter-spacing: 0.03em;
        text-align: left;
        margin-bottom: 37px;
        color: #2330ba;
        @media (max-width: 640px) {
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0.03em;
            text-align: left;
        }
    }

    &__docsRow {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 40px;
        row-gap: 27px;

        @media (max-width: 800px) {
            flex-direction: column;

            & > div {
                &:nth-child(odd) {
                    background-color: #fffdf0;
                }

                &:nth-child(even) {
                    background-color: #f0fcff;
                }
            }
        }
    }

    &__docsCol-yellow {
        background-color: #fffdf0;
    }

    &__docsCol-blue {
        background-color: #f0fcff;
    }

    &__docsCol {
        width: calc(50% - 25px);
        align-items: center;
        display: flex;
        padding: 13px 23px;
        border-radius: 20px;

        img {
            height: 42px;
            width: 42px;
        }

        a {
            font-size: 26px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #122412 !important;
            text-decoration: none;
            display: block;
            margin-left: 27px;
        }

        a:hover {
            text-decoration: underline;
        }

        @media (max-width: 800px) {
            width: 70%;
            margin-left: auto;
            margin-right: auto;
        }
        @media (max-width: 640px) {
            width: 329px;

            img {
                width: 16px;
                height: 16px;
            }
            a {
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                letter-spacing: 0.04em;
                text-align: left;
                margin-left: 20px;
            }
        }
    }

    &__cover {
        width: 450px;
        height: 450px;
        overflow: hidden;
        border-radius: 42px;
        margin-right: 2.5rem;

        @media (max-width: 1024px) {
            width: 100%;
            height: auto;
            margin-right: initial;
        }

        @media (max-width: 640px) {
            border-radius: 29px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    &__content {
        max-width: 570px;
        padding-top: 3rem;
        font-size: 28px;
        line-height: 150%;
        letter-spacing: 0.03em;
        color: #2330ba;

        @media (max-width: 1024px) {
            display: flex;
            flex-flow: column;
            align-items: center;
            padding-top: 2rem;
            max-width: initial;
            text-align: center;
        }

        @media (max-width: 640px) {
            font-size: 18px;
            font-weight: 500;
        }
    }

    &__steps {
        display: flex;
        margin-bottom: 2.5rem;

        @media (max-width: 1024px) {
            order: 1;
            margin-top: 2.5rem;
            margin-bottom: 0;
        }
    }

    &__step {
        display: flex;
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;
        background: #f0fcff;
        border-radius: 50%;

        font-weight: 600;
        font-size: 32px;
        line-height: 111%;
        letter-spacing: 0.08em;
        color: #2330ba;

        @media (max-width: 640px) {
            width: 62px;
            height: 62px;
            font-size: 26px;
        }

        & + & {
            margin-left: 60px;

            @media (max-width: 640px) {
                margin-left: 2.5rem;
            }
        }

        &_blue {
            color: #ffffff;
            background: #2330ba;
        }

        &_yellow {
            color: #ffffff;
            background: #ffbe4d;
        }

        &_orange {
            color: #ffffff;
            background: #ff8d4d;
        }
    }
}

.landing-contacts {
    width: 100%;
    background-color: #0f1445;
    background-repeat: no-repeat;
    background-image: url(../img/ellipse.svg);
    background-position: left -77px bottom -128px;
    padding: 120px calc((100% - 1140px) / 2) 0;

    @media (max-width: 1220px) {
        padding: 120px 2.5rem 0;
    }

    @media (max-width: 640px) {
        padding: 5rem 1.5rem 0;
        padding-top: 40px;
    }

    &__title {
        color: #fff;
        font-size: 72px;
        font-weight: 600;
        line-height: 110%;
        margin-bottom: 5rem;

        @media (max-width: 1024px) {
            font-size: 56px;
        }

        @media (max-width: 640px) {
            font-size: 26px;
            font-weight: 600;
            line-height: 110%;
            margin-bottom: 30px;
        }

        &_contacts {
            font-size: 48px;
            line-height: 70%;
            margin-bottom: 2.5rem;
        }
    }

    &__content {
        width: 100%;
        display: flex;

        @media (max-width: 1024px) {
            flex-flow: column;
        }
    }

    &__contacts {
        @media (max-width: 1024px) {
            order: 1;
        }
    }

    &__label {
        margin-top: 2.5rem;
        font-size: 18px;
        color: white;
        opacity: 0.5;
    }

    &__img {
        margin-top: 5rem;
        width: 180px;
        filter: brightness(0) invert(1);
    }

    &__img2 {
        margin-top: 5rem;
        margin-left: 8rem;
        height: 80px;

        @media (max-width: 640px) {
            margin-left: 3rem;
            margin-top: 5rem;
        }
        @media (max-width: 388px) {
            margin-left: 2rem;
            margin-top: 5rem;
        }
        @media (max-width: 372px) {
            margin-left: 0;
            margin-top: 5rem;
        }
        @media (max-width: 339px) {
            margin-left: -0.5rem;
            margin-top: 5rem;
        }
        @media (max-width: 331px) {
            margin-left: -0.5rem;
            margin-top: 2rem;
        }
        @media (max-width: 323px) {
            margin-left: -1rem;
            margin-top: 2rem;
        }
    }

    &__contact {
        display: flex;
        align-items: center;
        font-family: "Gilroy", sans-serif;
        font-size: 28px;
        line-height: 150%;
        letter-spacing: 0.03em;
        color: #ffffff;

        &::before {
            content: "";
            display: block;
            width: 3.5rem;
            height: 3.5rem;
            margin-right: 20px;
            border-radius: 50%;
            background-position: center;
            background-repeat: no-repeat;
            background-color: rgba(255, 255, 255, 0.06);
        }

        &_email::before {
            background-image: url(../img/ic-email.svg);
        }

        &_email {
            display: flex;

            div {
                display: flex;
                flex-direction: column;
            }

            p {
                display: block;
            }

            label {
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                letter-spacing: 0.03em;
                text-align: left;
                display: block;
            }
        }

        &_tel::before {
            background-image: url(../img/ic-tel.svg);
        }

        &_address {
            align-items: flex-start;
            cursor: initial;
        }

        &_address::before {
            background-image: url(../img/ic-loc.svg);
            min-width: 3.5rem;
        }

        & + & {
            margin-top: 2.5rem;
        }
    }

    &__form-wrapper {
        width: 50%;
        margin-left: auto;

        @media (max-width: 1024px) {
            width: 100%;
            margin-left: initial;
            margin-bottom: 2.5rem;
        }
    }

    &__form {
        display: flex;
        flex-flow: column;
        //width: 100%;
        height: 100%;
        padding: 10px;
        background: #fff;
        border-radius: 20px;

        @media (min-width: 640px) {
            padding: 30px;
            border-radius: 30px;
        }
    }

    &__form-steps {
        margin-bottom: 30px;

        span {
            display: inline-block;
            font-size: 14px;
            letter-spacing: 0.03em;
            color: rgba(35, 48, 186, 0.4);

            padding: 0 20px;
            line-height: 33px;
            border-radius: 1.5rem;
        }

        span:first-child {
            color: #fff;
            background: #030849;
        }
    }

    &__input-group {
        flex-grow: 1;
    }

    &__input-field {
        display: flex;
        flex-flow: column;
        margin-bottom: 1.5rem;

        label {
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.03em;
            margin-bottom: 0.5rem;
            color: #0f1445;
        }

        input,
        textarea {
            color: #0f1445;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.03em;
            padding: 15px 20px;
            border-radius: 10px;
            border: 1px solid rgba(35, 48, 186, 0.15);

            @media (min-width: 640px) {
                font-size: 18px;
                line-height: 150%;
                letter-spacing: 0.03em;
                border-radius: 12px;
            }
        }

        textarea {
            font-family: "Gilroy", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.03em;
            resize: none;

            @media (min-width: 640px) {
                font-size: 18px;
                line-height: 150%;
                letter-spacing: 0.03em;
                border-radius: 12px;
            }
        }

        input::placeholder,
        textarea::placeholder {
            color: rgba(15, 20, 69, 0.4);
        }
    }

    &__form-note {
        display: flex;
        align-items: center;

        padding: 1rem 20px;
        border-radius: 12px;

        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.03em;
        color: #030849;

        margin-bottom: 15px;

        &::before {
            content: "";
            min-width: 20px;
            height: 25px;
            display: block;
            margin-right: 20px;
            background: url(../img/ic-shield.svg) no-repeat center / contain;
        }
    }

    &__form-btns {
        display: flex;
    }

    &__form-btn {
        cursor: pointer;
        height: 53px;
        flex-grow: 1;
        font-size: 18px;
        line-height: 150%;
        border-radius: 8px;
        letter-spacing: 0.03em;
        transition: opacity 0.3s;

        &_back {
            background: rgba(0, 114, 255, 0.1);
            color: #2330ba;
        }

        &_forward {
            background: #fecc62;
            color: #122443;
        }

        & + & {
            margin-left: 10px;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    &__col {
        display: flex;
        flex-direction: column;

        @media (max-width: 1025px) {
            margin-right: auto;
            margin-top: 20px;
        }
    }

    &__p {
        margin-bottom: 20px;
    }

    &__p1 {
        max-width: 305px;
    }

    &__p2 {
        max-width: 330px;
    }

    &__p3 {
        width: 282px;
        margin-left: auto;
        text-align: right;

        @media (max-width: 1025px) {
            margin-left: 0;
            text-align: left;
        }
    }

    &__p4 {
        width: 282px;
        margin-left: auto;
        text-align: right;
        @media (max-width: 1025px) {
            margin-left: 0;
            text-align: left;
        }
    }

    &__row {
        display: flex;
        align-items: center;
    }

    &__row1 {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }

    &__row3 {
        margin-bottom: auto;

        a {
            transition-duration: 300ms;
            opacity: 0.3;
        }

        a:hover {
            opacity: 1;
            text-decoration: underline;
        }

        @media (max-width: 1025px) {
            margin-left: 0;
            text-align: left;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &__p3 {
        margin-top: 36px;
    }

    &__p4 {
        a {
            transition-duration: 300ms;
            color: white;
        }

        a:hover {
            color: white;
            opacity: 1 !important;
            text-decoration: underline;
        }
    }

    &__img1 {
        margin-right: 15px;
    }

    &__img3 {
        margin-right: 18px;
    }

    &__footer {
        display: flex;
        padding: 4rem 0;
        padding-bottom: 40px;

        @media (max-width: 1024px) {
            flex-flow: column;
            align-items: center;
        }

        p,
        a {
            opacity: 0.5;
            color: #fff;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.03em;
        }

        p:first-child {
            margin-right: auto;

            @media (max-width: 1024px) {
                margin-right: initial;
            }
        }

        a + a {
            margin-left: 2.5rem;

            @media (max-width: 1024px) {
                margin-left: initial;
            }
        }
    }
}

.landing-popup {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #00000099;
    justify-content: center;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 100px;
    overflow: scroll;
    z-index: 11;
    overflow-x: hidden;
}

.animatedCheckbox {
    display: flex;
    margin-bottom: 11px;

    .checkbox {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        border: 1px solid rgba(35, 48, 186, 0.3);
        border-radius: 2px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: 3px;
        cursor: pointer;

        svg {
            position: absolute;
        }
    }

    p {
        font-weight: 500;
        text-align: left;
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.03em;
        max-width: 440px;
        color: rgba(17, 36, 68, 0.4);

        a {
            color: rgba(17, 36, 68, 1);
        }

        @media (min-width: 1120px) and (max-width: 1180px) {
            max-width: 350px;
        }
        @media (max-width: 520px) {
            max-width: 210px;
        }
    }

    &__password {
        .checkbox {
            margin-top: 0;
            background-color: white;

            &.checked {
                background-color: #2330ba;
            }
        }

        p {
            font-size: 14px;
            color: #122443;
            opacity: 0.5;
        }
    }
}

// NEW

.landing {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    background: #fff;
    overflow: hidden;

    @media (min-width: 640px) {
        padding: 0 20px;
    }

    &__button {
        cursor: pointer;
        letter-spacing: 0.03em;
        transition: opacity 0.3s;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        padding: 18px 25px;
        border-radius: 10px;
        background: #e7f0ff;
        width: max-content;

        &-primary {
            background: rgba(0, 114, 255, 0.1);
            color: #2330ba;
        }

        &-secondary,
        &-profile {
            background: #fecc62;
            color: #122443;
        }

        &-profile,
        &-login {
            display: flex;
            column-gap: 10px;
            justify-content: center;
            align-items: center;
            padding: 13px 25px;

            img {
                width: 24px;
                height: 24px;
            }
        }

        &-login {
            background: #ffffff;

            &-wrapper {
                position: relative;
                width: 100%;

                @media (min-width: 640px) {
                    display: none;
                }
            }
        }

        @media (hover: hover) {
            &:hover {
                opacity: 0.7;
            }
        }
    }

    &__rounded-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: #2330ba;
        border-radius: 50%;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
        color: #fff;

        @media (min-width: 640px) {
            width: 50px;
            height: 50px;
        }
    }

    &__section {
        &-title {
            font-weight: 600;
            font-size: 30px;
            line-height: 100%;
            text-align: center;
            color: #0f1445;

            @media (min-width: 640px) {
                font-size: 70px;
            }
        }

        &-block {
            &-dark {
                background: #2330ba;
                border-radius: 10px;

                @media (min-width: 640px) {
                    border-radius: 20px;
                }
            }
        }
    }

    &__hero {
        display: flex;
        align-items: center;
        flex-flow: column;
        position: relative;
        background: #e7f0ff;
        margin: 0 10px;
        padding: 10px;
        border-radius: 20px;

        @media (min-width: 640px) {
            margin: 0;
            align-items: flex-start;
            border-radius: 30px;
        }

        &-title {
            position: relative;
            margin-top: 50px;
            max-width: 280px;
            text-align: left;

            &:before {
                position: absolute;
                content: "Univer.TestU.online";
                left: 0;
                top: -23px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: #ffffff;
                padding: 5px 20px;
                background-color: #2330ba;
                border-radius: 30px;
                transform: rotate(-3.2deg);
            }

            @media (min-width: 640px) {
                margin-left: 20px;
                margin-top: 140px;
                max-width: 980px;

                &:before {
                    top: -45px;
                    font-size: 40px;
                }
            }
        }

        &-subtitle {
            margin-top: 15px;
            max-width: 280px;
            font-weight: 500;
            font-size: 12px;
            line-height: 130%;
            text-align: left;
            color: #0f1445;

            @media (min-width: 640px) {
                margin-top: 20px;
                margin-left: 20px;
                max-width: 480px;
                font-size: 20px;
            }
        }

        &-buttons {
            margin-top: 15px;
            width: 280px;

            @media (min-width: 640px) {
                margin-top: 30px;
            }

            &-auth,
            &-want {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                a,
                button {
                    width: 100%;
                    text-align: center;
                }

                @media (min-width: 640px) {
                    justify-content: flex-start;
                    align-items: center;
                    margin-left: 20px;

                    a,
                    button {
                        width: auto;
                    }
                }
            }

            &-want {
                margin-top: 16px;
            }

            a {
                display: inline-block;
            }

            &-login {
                position: absolute;
                display: flex;
                flex-direction: column;
                row-gap: 5px;
                width: 100%;
                bottom: calc(100% + 5px);
                left: 0;

                a {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 18px 25px;
                    background: #ffffff;
                    border-radius: 10px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    color: #0f1445;
                }
            }

            &-profile {
                width: 280px;
                text-align: center;

                @media (min-width: 640px) {
                    width: auto;
                    margin-left: 20px;
                }
            }
        }

        &-image {
            &-small {
                margin-top: 20px;

                img {
                    width: 220px;
                }

                @media (min-width: 640px) {
                    display: none;
                }
            }
        }

        &-blocks {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 20px 10px;

            &-text {
                display: flex;
                justify-content: center;
                align-items: center;
                row-gap: 20px;
                flex-wrap: wrap;
            }

            &-img {
                display: none;
                position: relative;
                width: 508px;
                margin-right: 40px;

                img {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 508px;
                }
            }

            @media (min-width: 640px) {
                margin-top: 60px;
                column-gap: 20px;
                padding: 0;

                &-text {
                    width: 100%;
                    max-width: 950px;
                    padding: 20px 20px 45px;
                    justify-content: flex-start;
                    align-items: flex-start;
                    column-gap: 50px;
                    flex-wrap: nowrap;
                }

                &-img {
                    display: block;
                }
            }
        }

        &-block {
            width: 100%;

            @media (min-width: 640px) {
                max-width: 300px;
            }

            &-icon {
                display: flex;
                justify-content: center;

                img {
                    width: 40px;
                    height: 40px;
                }

                @media (min-width: 640px) {
                    justify-content: flex-start;

                    img {
                        width: 56px;
                        height: 56px;
                    }
                }
            }

            p {
                margin-top: 15px;
                text-align: center;
                color: #fff;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 130%;

                @media (min-width: 640px) {
                    font-size: 16px;
                    text-align: left;
                }
            }
        }
    }

    &__about {
        margin: 80px 10px 0;

        @media (min-width: 640px) {
            margin: 140px 0 0;
            font-size: 65px;
            line-height: 100%;
        }

        &-title {
            font-weight: 600;
            font-size: 25px;
            line-height: 100%;
            color: #0f1445;

            @media (min-width: 640px) {
                font-size: 65px;
                line-height: 100%;
            }
        }

        &-content {
            display: grid;
            justify-content: center;
            grid-template-areas:
                "left"
                "right"
                "button"
                "center";
            gap: 30px;
            margin-top: 40px;

            @media (min-width: 640px) {
                column-gap: 80px;
                row-gap: 40px;
                margin-top: 45px;
            }

            @media (min-width: 1260px) {
                grid-template-areas:
                    "left center right"
                    "button button button";
            }

            &-block {
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 30px;
                }

                @media (min-width: 640px) {
                    width: 350px;

                    &:not(:last-child) {
                        margin-bottom: 60px;
                    }
                }

                &--left,
                &--right {
                    text-align: center;
                }

                @media (min-width: 1260px) {
                    &--left {
                        text-align: left;
                    }

                    &--right {
                        text-align: right;
                    }
                }

                img {
                    width: 32px;
                    height: 32px;

                    @media (min-width: 640px) {
                        width: 48px;
                        height: 48px;
                    }
                }

                p {
                    margin-top: 15px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 130%;
                    color: #0f1445;

                    @media (min-width: 640px) {
                        font-size: 18px;
                        margin-top: 20px;
                    }
                }
            }

            &-left {
                grid-area: left;
            }

            &-right {
                grid-area: right;
            }

            @media (min-width: 640px) {
                &-left,
                &-right {
                    margin-top: 70px;
                }
            }

            &-center {
                grid-area: center;
                text-align: center;

                img {
                    width: 100%;
                    padding: 0 10px;

                    @media (min-width: 640px) {
                        width: 339px;
                        padding: 0;
                    }
                }

                @media (max-width: 1200px) {
                    img {
                        width: 300px;
                    }
                }
            }
        }

        &-button {
            grid-area: button;
            text-align: center;

            button {
                padding: 18px 77px;
            }
        }

        &-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: 20px;
            margin-top: 20px;
            padding: 20px;

            img {
                width: 32px;
                height: 32px;
            }

            @media (min-width: 640px) {
                padding: 60px;
                margin-top: 60px;
                column-gap: 50px;
                flex-direction: row;

                img {
                    width: 56px;
                    height: 56px;
                }
            }

            &-text {
                p {
                    max-width: 800px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 130%;
                    letter-spacing: 0.03em;
                    color: #fff;

                    @media (min-width: 640px) {
                        font-size: 18px;
                        line-height: 140%;
                    }

                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    &__problems {
        margin: 10px 10px 0;
        background-color: #0f1445;
        border-radius: 20px;
        padding-top: 20px;

        &-title {
            font-weight: 500;
            font-size: 43px;
            line-height: 100%;
            padding: 0 5px;
            text-align: center;
            background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            opacity: 0.6;
        }

        &-img {
            margin-top: 15px;
            width: 100%;
            height: 250px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        @media (min-width: 640px) {
            margin: 20px 0 0;

            &-title {
                font-size: 220px;
            }

            &-img {
                margin-top: -50px;
                height: 100%;
            }
        }
    }

    &__documents {
        margin: 80px 10px 0;

        @media (min-width: 640px) {
            margin: 140px 0 0;
        }

        &-title {
            font-size: 25px;
            line-height: 100%;

            @media (min-width: 640px) {
                font-size: 65px;
            }
        }

        &-subtitle {
            margin: 15px auto 0;
            max-width: 600px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 130%;
            text-align: center;
            letter-spacing: 0.03em;
            color: #0f1445;

            @media (min-width: 640px) {
                margin-top: 20px;
                font-size: 16px;
                line-height: 140%;
            }
        }

        &-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 20px;

            @media (min-width: 640px) {
                margin-top: 40px;
                gap: 15px;
            }

            &-block {
                position: relative;
                display: flex;
                align-items: flex-end;
                padding: 10px;
                width: 145px;
                height: 200px;
                background: #e7f0ff;
                border-radius: 20px;

                &::after {
                    position: absolute;
                    content: "";
                    background-image: url("../img/landing/arrow-northeast.svg");
                    background-size: 100%;
                    width: 40px;
                    height: 40px;
                    top: 10px;
                    right: 10px;
                }

                @media (min-width: 640px) {
                    width: 510px;
                    height: 255px;
                    padding: 30px;

                    &::after {
                        width: 60px;
                        height: 60px;
                        top: 20px;
                        right: 20px;
                    }
                }

                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 130%;
                    color: #0f1445;
                    transition: color 0.3s;

                    @media (min-width: 640px) {
                        font-size: 20px;
                        line-height: 120%;
                    }
                }

                @media (hover: hover) {
                    &:hover span {
                        color: #2330ba;
                    }
                }
            }
        }
    }

    &__why {
        margin: 10px 10px 0;
        background: #e7f0ff;
        padding: 10px;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        row-gap: 20px;

        @media (min-width: 640px) {
            margin: 20px 0 0;
            border-radius: 30px;
            padding: 10px;
        }

        @media (max-width: 1319px) {
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }

        &-title {
            margin: 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 100%;
            text-align: center;

            @media (min-width: 640px) {
                font-size: 45px;
                text-align: left;
            }
        }

        &-block {
            &--left {
                flex: 1 0 50%;
                display: flex;
                flex-flow: column;
                justify-content: space-between;
                width: 810px;
                padding: 40px 0 0;
                row-gap: 20px;

                @media (min-width: 640px) {
                    padding: 30px 30px 0 20px;
                    row-gap: 30px;
                }

                @media (max-width: 859px) {
                    width: 100%;
                }
            }

            &--right {
                position: relative;
                flex: 1 1 auto;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                padding-top: 30px;
                max-width: 730px;
                width: 100%;
                overflow: hidden;

                div.ellipse {
                    position: absolute;
                    inset: 0;
                    width: 100%;
                    height: 100%;
                    background: #73ffe6;
                    border-radius: 50%;
                    opacity: 0.5;
                    filter: blur(100px);
                    z-index: 0;
                }

                img {
                    width: 160px;
                    z-index: 1;
                }

                @media (min-width: 640px) {
                    padding-top: 100px;

                    img {
                        width: auto;
                    }
                }
            }
        }

        &-buttons-tab {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            padding: 4px;
            background: #2330ba;
            border-radius: 12px;

            @media (min-width: 640px) {
                margin-top: 20px;
                border-radius: 20px;
            }

            button {
                flex: 1;
                padding: 10px 8px;
                color: #fff;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                border-radius: 10px;

                @media (min-width: 640px) {
                    padding: 18px 25px;
                    font-size: 14px;
                    border-radius: 16px;
                }

                &.active {
                    color: #2330ba;
                    background: #fff;
                }
            }
        }

        &-content {
            display: flex;
            flex-wrap: wrap;
            row-gap: 40px;
            column-gap: 80px;
            margin-top: 40px;

            @media (max-width: 640px) {
                margin-top: 25px;
            }

            @media (max-width: 859px) {
                column-gap: 30px;
            }

            @media (max-width: 1319px) {
                justify-content: space-between;
            }

            &-item {
                width: 300px;

                @media (max-width: 640px) {
                    width: 130px;
                    margin: auto;

                    span {
                        margin: auto;
                    }
                }

                @media (max-width: 740px) {
                    width: 250px;
                }

                p {
                    margin-top: 10px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 130%;
                    color: #0f1445;
                    text-align: center;

                    @media (min-width: 640px) {
                        font-size: 16px;
                        text-align: left;
                    }
                }
            }
        }

        &-button-want {
            display: inline-block;
            width: 100%;
        }
    }

    &__methodology {
        position: relative;
        overflow: hidden;
        background-color: #2330ba;

        &-notebook {
            position: absolute;
            top: 290px;
            right: -20px;
            width: 220px;
            z-index: 1;
            pointer-events: none;

            @media (min-width: 640px) {
                top: 190px;
                left: 0;
                right: auto;
                width: 570px;
            }
        }

        &-ellipse {
            position: absolute;
            top: 0;
            right: 0;
            width: 1100px;
            height: 1110px;
            background: #73ffe6;
            border-radius: 50%;
            opacity: 0.3;
            filter: blur(150px);
        }

        &-separator {
            position: relative;
            height: 20px;
            z-index: 10;

            @media (min-width: 640px) {
                height: 30px;
            }

            div {
                height: 100%;
                background-color: #fff;
            }

            &--top div {
                border-radius: 0 0 20px 20px;
            }

            &--bottom div {
                border-radius: 20px 20px 0 0;
            }

            @media (min-width: 640px) {
                &--top div {
                    border-radius: 0 0 30px 30px;
                }

                &--bottom div {
                    border-radius: 30px 30px 0 0;
                }
            }
        }

        &-inner {
            position: relative;
            padding: 0 10px;
            margin-bottom: 80px;
            z-index: 10;
        }

        &-block-text {
            max-width: 1200px;
            margin-top: 80px;

            @media (min-width: 640px) {
                margin-top: 80px;
                margin-left: 30px;
            }
        }

        &-title {
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 110%;
            text-align: left;
            color: #fff;
            letter-spacing: 0.03em;

            @media (min-width: 640px) {
                font-size: 65px;
                line-height: 100%;
            }

            span {
                position: relative;
            }

            span.comfortable {
                padding: 0 10px;
                color: #2330ba;

                &:after {
                    position: absolute;
                    content: "";
                    top: 3px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    background: #fff;
                    border-radius: 70px;
                    z-index: -1;
                    transform: rotate(1deg);
                    -webkit-transform: rotate(1deg);
                }
            }

            span.product {
                &:after {
                    position: absolute;
                    content: "";
                    left: 0;
                    right: 0;
                    bottom: -2px;
                    width: 100%;
                    height: 7px;
                    background-image: url("../img/landing/green-line-small.svg");
                    background-repeat: no-repeat;
                    z-index: -1;

                    @media (min-width: 640px) {
                        bottom: -3px;
                        background-image: url("../img/landing/green-line.svg");
                        height: 15px;
                    }
                }
            }
        }

        &-subtitle {
            margin: 15px auto 0;
            max-width: 420px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.04em;
            color: #ffffff;

            @media (min-width: 640px) {
                font-size: 18px;
                line-height: 130%;
                margin: 60px 0 0 auto;
            }
        }

        &-content {
            display: grid;
            align-items: center;
            gap: 10px;
            grid-template-areas:
                "first second"
                "third second";
            margin-top: 80px;

            @media (min-width: 640px) {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: nowrap;
            }

            &-block {
                display: flex;
                flex-flow: column;
                justify-content: flex-end;
                min-width: 145px;
                width: 100%;
                background: #fff;
                border-radius: 20px;
                padding: 20px 10px;

                &:nth-child(1) {
                    grid-area: first;
                }

                &:nth-child(2) {
                    grid-area: second;
                }

                &:nth-child(3) {
                    grid-area: third;
                }

                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 50px;
                    line-height: 100%;
                    color: #2330ba;

                    @media (min-width: 640px) {
                        font-size: 100px;
                    }
                }

                img {
                    margin-top: 10px;
                    width: 50px;
                    height: 50px;
                    align-self: center;
                }

                p {
                    margin-top: 20px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 130%;
                    color: #0f1445;
                }

                @media (min-width: 640px) {
                    padding: 30px;
                    flex-basis: 100%;

                    &:nth-child(1) {
                        margin-top: 200px;
                    }

                    &:nth-child(3) {
                        margin-top: 107px;
                    }

                    span {
                        font-size: 100px;
                    }

                    img {
                        margin-top: 30px;
                        width: 100px;
                        height: 100px;
                    }

                    p {
                        margin-top: 100px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    &__methodologists {
        position: relative;
        margin: 0 10px;
        padding: 40px 10px 20px;
        border-radius: 20px;
        background-color: #0f1445;
        overflow: hidden;

        &-title {
            font-weight: 600;
            font-size: 24px;
            line-height: 100%;
            text-align: center;
            color: #ffffff;

            span {
                position: relative;
                padding: 0 5px;
                z-index: 1;

                &:after {
                    position: absolute;
                    content: "";
                    inset: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #2330ba;
                    border-radius: 60px;
                    z-index: -1;
                    transform: rotate(-2deg);
                    -webkit-transform: rotate(-2deg);
                }
            }
        }

        @media (min-width: 640px) {
            margin: 0 20px;
            padding: 100px 10px 100px;

            &-title {
                position: relative;
                font-size: 65px;

                span {
                    padding: 0 15px;
                }
            }
        }

        &-content {
            position: relative;
            margin-top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            row-gap: 30px;

            &-block {
                text-align: center;
                color: #ffffff;

                img {
                    width: 150px;
                    height: 150px;
                }

                &-name {
                    margin-top: 15px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 110%;
                }

                &-desc {
                    margin-top: 10px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 130%;

                    p {
                        margin: 0 auto;
                        width: 250px;
                        padding: 10px;
                        background: #2330ba;
                        border-radius: 10px;

                        &:not(:first-child) {
                            margin-top: 2px;
                        }

                        &.big {
                            width: 280px;
                        }
                    }
                }
            }

            @media (min-width: 640px) {
                margin-top: 60px;
                flex-direction: row;
                column-gap: 100px;

                &-block {
                    img {
                        width: 300px;
                        height: 300px;
                    }

                    &-name {
                        font-size: 25px;
                        line-height: 110%;
                    }

                    &-desc {
                        margin-top: 15px;
                        font-size: 16px;

                        p {
                            width: 300px;

                            &.big {
                                width: 350px;
                            }
                        }
                    }
                }
            }
        }

        &-line {
            position: absolute;
            content: "";
            background-repeat: no-repeat;
            background-size: 100%;
            inset: 0;
            z-index: 0;

            &--blue {
                background-image: url("../img/landing/blue-line-small.svg");
                top: 91px;
            }

            &--yellow {
                background-image: url("../img/landing/yellow-line-small.svg");
                top: 288px;
            }

            @media (min-width: 640px) {
                background-size: auto;

                &--blue {
                    background-image: url("../img/landing/blue-line.svg");
                    left: 0;
                    top: 30px;
                }

                &--yellow {
                    background-image: url("../img/landing/yellow-line.svg");
                    top: 0;
                    left: 1024px;
                }
            }
        }
    }

    &__company {
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        padding: 0 10px 10px;
        margin-top: 80px;

        @media (min-width: 640px) {
            justify-content: space-between;
            flex-wrap: nowrap;
            padding: 0 20px 20px;
            margin-top: 100px;
        }

        &-title {
            font-size: 20px;
            line-height: 120%;
            font-weight: 600;
            text-align: left;

            @media (min-width: 640px) {
                font-size: 35px;
                line-height: 110%;
            }
        }

        &-subtitle {
            margin-top: 20px;
            flex-grow: 1;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            color: #0f1445;

            @media (min-width: 640px) {
                margin-top: 30px;
                font-size: 18px;
            }
        }

        &-signature {
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 30px;

            img {
                width: 40px;
                height: 40px;
            }

            p {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 115%;
                color: #0f1445;
                padding: 0 50px 0 10px;
                z-index: 10;
            }

            @media (min-width: 640px) {
                margin-top: 30px;

                img {
                    width: 80px;
                    height: 80px;
                }

                p {
                    font-size: 20px;
                    line-height: 120%;
                    width: 350px;
                    padding: 0 50px 0 20px;
                }
            }
        }

        &-block {
            &--left {
                position: relative;
                display: flex;
                flex-flow: column;
                max-width: 850px;

                &:after {
                    position: absolute;
                    content: "";
                    background-image: url("../img/landing/company-quotes.svg");
                    background-size: 100%;
                    bottom: 0;
                    right: 0;
                    width: 46px;
                    height: 49px;
                    z-index: 0;
                }

                @media (min-width: 640px) {
                    padding: 30px 50px 0 0;

                    &:after {
                        right: 50px;
                        width: 100px;
                        height: 105px;
                    }
                }
            }

            &--right {
                flex: 1 1 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 730px;

                @media (min-width: 640px) {
                    min-width: 330px;
                    height: auto;
                }

                img {
                    width: 100%;
                }
            }
        }
    }

    &__contacts {
        margin-top: 70px;
        padding: 30px 10px 0;
        background-color: #2330ba;
        border-radius: 30px 30px 0 0;

        @media (min-width: 640px) {
            padding: 20px;
            border-radius: 40px 40px 0 0;
        }

        &-title {
            max-width: 810px;
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 100%;
            color: #fff;

            @media (min-width: 640px) {
                margin: 80px auto 0;
                font-size: 65px;
            }
        }

        &-block {
            display: grid;
            grid-template-areas:
                "form"
                "contacts";
            gap: 10px;
            margin-top: 30px;

            @media (min-width: 640px) {
                margin-top: 50px;
                grid-template-areas: "contacts form";
                grid-template-columns: repeat(2, 1fr);
            }

            &--left {
                grid-area: contacts;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 20px;
                flex-basis: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 50px 10px;

                @media (min-width: 640px) {
                    border-radius: 30px;
                    height: 600px;
                }
            }

            &--right {
                grid-area: form;
                flex-basis: 50%;
            }
        }

        &-contact {
            display: flex;
            align-items: center;
            column-gap: 20px;
            font-style: normal;
            font-weight: 500;
            color: #fff;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            img {
                width: 40px;
                height: 40px;
            }

            span {
                font-size: 10px;
                line-height: 100%;
            }

            p {
                margin-top: 5px;
                font-size: 18px;
                line-height: 110%;
            }

            @media (min-width: 640px) {
                &:not(:last-child) {
                    margin-bottom: 30px;
                }

                img {
                    width: 56px;
                    height: 56px;
                }

                span {
                    font-size: 16px;
                }

                p {
                    font-size: 25px;
                }
            }
        }

        & .animatedCheckbox {
            p {
                font-weight: 500;
                text-align: left;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: 0.03em;
                max-width: 100%;
                color: rgba(3, 8, 73, 0.5);

                a {
                    color: rgba(35, 48, 186, 1);
                    text-decoration: underline;
                }

                @media (min-width: 1120px) and (max-width: 1180px) {
                    max-width: 350px;
                }
                @media (max-width: 520px) {
                    max-width: 210px;
                }
            }
        }
    }

    &__footer {
        padding: 15px;
        background-color: #2330ba;

        &-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            column-gap: 40px;
            row-gap: 30px;
            margin-top: 30px;
            margin-bottom: 40px;
        }

        @media (min-width: 640px) {
            padding: 20px;

            &-content {
                margin-top: 80px;
            }
        }

        &-images {
            display: flex;
            justify-content: space-between;
            width: 100%;

            &-logo {
                width: 154px;
                height: 51px;
            }

            &-sk {
                width: 85px;
                height: 60px;
            }

            @media (min-width: 640px) {
                display: block;
                width: auto;

                &-logo {
                    width: 231px;
                    height: 76px;
                }

                &-sk {
                    width: 127px;
                    height: 91px;
                    margin-left: 40px;
                }
            }
        }

        &-center {
            margin: auto;
            width: 100%;

            @media (min-width: 640px) {
                margin: 0;
                width: 450px;
            }

            &-first {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                row-gap: 10px;
                column-gap: 20px;
            }

            p,
            a {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: 0.03em;
                color: #ffffff;
                opacity: 0.8;
                text-align: center;
            }

            p:not(:last-child) {
                margin-bottom: 42px;
            }

            @media (hover: hover) {
                a:hover {
                    opacity: 1;
                    text-decoration: underline;
                }
            }
        }

        &-right {
            display: flex;
            column-gap: 15px;
            width: 100%;
            margin: auto;

            @media (min-width: 640px) {
                margin: 0;
                max-width: 350px;
            }

            img {
                width: 20px;
                height: 20px;
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #fff;
                opacity: 0.5;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }
    }

    &__popup {
        position: fixed;
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 100;

        &-inner {
            position: relative;
            width: 100%;
            height: 100%;
            max-width: 1560px;
            padding: 10px;
            background-color: #fff;
            padding-bottom: calc(20px + env(safe-area-inset-bottom, 20px));
        }

        @media (min-width: 640px) {
            padding: 20px;

            &-inner {
                border-radius: 30px;
                padding: 20px;
            }
        }

        &-body {
            display: flex;
            justify-content: space-between;
            column-gap: 20px;
            height: 100%;

            img {
                height: 100%;
            }
        }

        &-image {
            display: none;

            @media (min-width: 640px) {
                flex: 1 1 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 750px;
                min-width: 330px;

                img {
                    width: 100%;
                    height: 100%;
                    //object-fit: cover;
                }
            }
        }

        &-content {
            width: 100%;
            max-width: 750px;
            font-style: normal;
            color: #0f1445;
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            &-flex {
                display: flex;
                justify-content: space-between;
                flex-flow: column;
                gap: 20px;
                height: 100%;

                &-bottom {
                    &.step2 {
                        flex: 1 1 100%;
                        display: flex;
                        flex-flow: column;
                        justify-content: space-between;
                    }

                    p {
                        padding-bottom: 10px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 130%;
                        text-align: center;
                        color: rgba(15, 20, 69, 0.5);

                        a {
                            color: #2330ba;
                            text-decoration: underline;
                            transition: opacity 0.3s;

                            @media (hover: hover) {
                                &:hover {
                                    opacity: 0.7;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }

            &-title {
                margin-top: 70px;
                font-weight: 600;
                font-size: 25px;
                line-height: 120%;
                letter-spacing: -0.03em;

                @media (min-width: 640px) {
                    margin-top: 0;
                    font-size: 45px;
                    line-height: 110%;
                    padding-right: 70px;
                }
            }

            &-subtitle {
                width: 100%;
                max-width: 360px;
                margin-top: 15px;
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: 0.03em;

                @media (min-width: 640px) {
                    margin-top: 20px;
                    font-size: 16px;
                }
            }

            &-proposal {
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                margin-top: 30px;
                max-width: 750px;

                &-item {
                    width: 130px;

                    p {
                        margin-top: 10px;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 130%;
                    }

                    @media (min-width: 640px) {
                        width: 300px;

                        p {
                            font-size: 16px;
                            line-height: 140%;
                        }
                    }
                }
            }

            &-form {
                margin-top: 20px;

                @media (min-width: 640px) {
                    margin-top: 30px;
                }

                &-field {
                    width: 100%;
                    margin-bottom: 10px;

                    &.error {
                        input[type="text"],
                        input[type="email"],
                        input[type="number"],
                        input[type="password"],
                        input[type="date"] {
                            color: #eb3349;
                            border-color: #eb3349;

                            &::placeholder {
                                color: #eb3349;
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }

            &-button {
                width: 100%;
                font-weight: 500;
                font-size: 18px;
                line-height: 100%;
                color: #0f1445;
                padding: 20px 35px;
                margin-bottom: 20px;
            }
        }

        &-close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 40px;
            height: 40px;
            background: #e7f0ff;
            border-radius: 10px;
            transition: opacity 0.3s;

            @media (min-width: 640px) {
                top: 20px;
                right: 20px;
                width: 50px;
                height: 50px;
            }

            @media (hover: hover) {
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    &__mobile-menu {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        background-color: #e7f0ff;
        padding: 10px;
        z-index: 90;

        &-nav {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 95px auto 0;
            width: 300px;

            background: #ffffff;
            border-radius: 20px;

            a {
                position: relative;
                width: 100%;
                padding: 25px 20px;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #0f1445;

                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }

                &:after {
                    position: absolute;
                    content: "";
                    top: 22px;
                    right: 25px;
                    width: 20px;
                    height: 20px;
                    background-image: url("../img/landing/arrow-right.svg");
                }
            }
        }

        &-buttons {
            &-enter {
                position: relative;
                margin-top: 5px;
            }

            &-login {
                position: absolute;
                display: flex;
                flex-direction: column;
                row-gap: 5px;
                width: 100%;
                top: calc(100% + 5px);
                left: 0;

                a {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 18px 25px;
                    background: #ffffff;
                    border-radius: 10px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    color: #0f1445;
                }
            }
        }

        &-auth {
            margin: 0 auto;

            a,
            button {
                width: 300px;
            }
        }
    }
}
